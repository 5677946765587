import React from "react";
import { graphql, Link, navigate } from "gatsby";
import styled from "styled-components";
import { Layout } from "../../layouts/Layout";
import {
  HeroBackgroundImage,
  HeroSection,
  HeroInformationRow,
} from "../../components/Hero";
import { elevation, below } from "../../utilities";
import { AllBlobPostProps } from "../../utilities/types/blog/types";

const PostListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  z-index: 100;
`;

const PostList = styled.ul`
  list-style: none;
  width: 65%;
  li {
    margin-bottom: 24px;
  }

  :last-of-type(li) {
    margin-bottom: none;
  }

  ${below.medium`
    width: 100%;
  `};
`;

interface PostListItemProps {
  slug: string;
  title: string;
  excerpt: string;
  timetoread: number;
  createdOn: string;
}

const PostListItemWrapper = styled.div`
  transition: 0.2s all;
  min-height: 150px;
  border: 1px solid ${(props) => props.theme.colors.background.secondary20};
  border-radius: 3px;
  padding: 16px;
  background: ${(props) => props.theme.colors.background.secondary20};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
  cursor: pointer;
  :hover {
    ${elevation[3]}
  }
`;

const PostListItemTitleSection = styled.div`
  display: flex;
  justify-content: space-between;

  ${below.medium`
    flex-direction: column;
    line-height: 2rem;
  `};
`;

const PostListItemMetaSection = styled.div`
  display: flex;
  justify-content: space-between;

  .timeToRead {
    font-size: 14px;
  }
`;

const PostListItem = (
  props: PostListItemProps & React.ComponentProps<"li">
) => {
  return (
    <li key={props.id}>
      <PostListItemWrapper onClick={() => navigate(`/blog/${props.slug}`)}>
        <div style={{ lineHeight: "1.3rem" }}>
          <PostListItemTitleSection>
            <h2>
              <Link to={`/blog/${props.slug}`}>{props.title}</Link>
            </h2>
            <span>Created on {props.createdOn}</span>
          </PostListItemTitleSection>
          <PostListItemMetaSection>
            <span className={"timeToRead"}>{props.timetoread} minute read</span>
          </PostListItemMetaSection>
        </div>
        <p>{props.excerpt}</p>
      </PostListItemWrapper>
    </li>
  );
};

const BlogReadTemplate = ({ data }: AllBlobPostProps) => {
  return (
    <Layout>
      <HeroSection>
        <HeroBackgroundImage />
        <HeroSection>
          <HeroInformationRow>
            <div className={"pageTitle"}>
              <h1>Blog</h1>
              <h3>Writing about tech, music, and the great outdoors</h3>
            </div>
          </HeroInformationRow>
        </HeroSection>
      </HeroSection>
      <PostListWrapper>
        <PostList>
          {data.allContentfulBlogPost.edges.map(({ node }) => {
            return (
              <PostListItem
                excerpt={node.body.childMarkdownRemark.excerpt}
                slug={node.slug}
                timetoread={node.body.childMarkdownRemark.timeToRead}
                title={node.title}
                id={node.id}
                key={node.id}
                createdOn={node.createdAt}
              />
            );
          })}
        </PostList>
      </PostListWrapper>
    </Layout>
  );
};

export const query = graphql`
  query posts {
    allContentfulBlogPost(sort: { order: DESC, fields: [date] }) {
      edges {
        node {
          body {
            childMarkdownRemark {
              excerpt
              html
              timeToRead
            }
          }
          id
          slug
          title
          createdAt(formatString: "DD MMM YYYY")
        }
      }
    }
  }
`;

export default BlogReadTemplate;
